/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Title from "../extra/Title";
import { Box } from "@mui/material";
import AddDetailsIcon from "@mui/icons-material/AddCircle";
import { ReactComponent as MonetizationIcon } from "../../assets/images/icon/MonetizationIcon.svg";
import { ReactComponent as OtherSettingIcon } from "../../assets/images/icon/OtherSetting.svg";
import { ReactComponent as SettingIcon } from "../../assets/images/icon/SettingIcon.svg";
import { ReactComponent as PlanIcon } from "../../assets/images/icon/PlanIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/images/icon/UserIcon.svg";
import TvIcon from '@mui/icons-material/Tv';
import { ReactComponent as NotificationIcon } from "../../assets/images/icon/NotificationIcon.svg";
import AddDetails from "./EditApp/AddDetails";
import Monetization from "./EditApp/Monetization";
import AppsIcon from "@mui/icons-material/Apps";
import OtherSetting from "./EditApp/OtherSetting";
import AppEditSetting from "./EditApp/AppEditSetting";
import PlanEdit from "./EditApp/PlanEdit";
import PlanTable from "../Table/PlanTable";
import UserEdit from "./EditApp/UserEdit";
import PushNotification from "./EditApp/PushNotification";
import JsonFormatter from "./EditApp/JsonFormatter";
import VipPlanTable from "./VipPlanTable";
import { useLocation } from "react-router-dom";
import { baseURL } from "../utils/config";
import { useSelector } from "react-redux";
import Banner from "./Banner";
import Monetization2 from "./EditApp/Monetization2";
import Tester from "./Tester";

const EditApp = () => {
  const location = useLocation();
  const getValue = localStorage.getItem("appEdit");
  const { appDetails } = useSelector((state) => state.app);
  const [showAppLogo,setShowAppLogo]=useState("")
  const [appName,setAppName]=useState("")
  const [value, setValue] = useState(Number(getValue) ? Number(getValue) : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("appEdit", newValue);
  };

  const renderEditComponent = React.useCallback(() => {
    switch (value) {
      case 0:
        return <AddDetails />;
      case 1:
        return <Monetization />;
      case 2:
        return <Monetization2 />;
      case 3:
        return <OtherSetting />;
      case 4:
        return <AppEditSetting />;
      case 5:
        return <PlanTable />;
      case 6:
        return <VipPlanTable />;
      case 7:
        return <UserEdit />;
        case 8:
          return <Tester />;
      case 9:
        return <PushNotification />;
      case 10:
        return <JsonFormatter />;
      case 11:
        return <Banner />;
      default:
        break;
    }
  }, [value]);

  useEffect(() => {
    if(appDetails && Object.values(appDetails)?.length > 0){
      localStorage.setItem("appHead",JSON.stringify(appDetails))
    }
    const dataGet=JSON.parse(localStorage.getItem("appHead"))

    if(dataGet&&Object.values(dataGet)?.length > 0){
      setShowAppLogo(baseURL + "/" + dataGet?.appLogo)
      setAppName(dataGet?.appName)
    }else{
      setShowAppLogo(baseURL + "/" + appDetails?.appLogo)
      setAppName(appDetails?.appName)
    }
    
  }, [appDetails])
  
  return (
    <div className="new-appEdit">
      <div className="app-edit-body">
        <div className="top-head-edit">
          <Title title={"Apps/ Edit"} />
          <div className="app-nameDetails">
          <img src={showAppLogo ? showAppLogo :""} alt=""/>
          <h6>{appName ? appName :"-"}</h6>
          </div>
        </div>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon tabs example"
          >
            <Tab icon={<AppsIcon />} iconPosition="start" label="App Details" />
            <Tab
              icon={<MonetizationIcon />}
              iconPosition="start"
              label="Monetization"
            />

            <Tab
              icon={<MonetizationIcon />}
              iconPosition="start"
              label="Monetization2"
            />
            <Tab
              icon={<OtherSettingIcon />}
              iconPosition="start"
              label="Other Setting"
            />
            <Tab icon={<SettingIcon />} iconPosition="start" label="Setting" />
            <Tab icon={<PlanIcon />} iconPosition="start" label="Plan" />
            <Tab icon={<PlanIcon />} iconPosition="start" label="Vip Plan" />
            <Tab icon={<UserIcon />} iconPosition="start" label="User" />
            <Tab icon={<UserIcon />} iconPosition="start" label="Tester" />
            <Tab
              icon={<NotificationIcon />}
              iconPosition="start"
              label="Push Notification"
            />
            <Tab
              icon={<NotificationIcon />}
              iconPosition="start"
              label="JSON Formatter"
            />
          <Tab icon={<TvIcon />} iconPosition="start" label="Banner" />
          </Tabs>

        </Box>
      </div>
      <div className="edit-renderComponent">{renderEditComponent()}</div>
    </div>
  );
};

export default EditApp;
