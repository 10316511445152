import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/Api/axiosApi";
import { setToast } from "../../Component/Api/toastServices";

const initialState = {
  allApp: [],
  totalApp: "",
  devloperAll: [],
  monetizationData: {},
  appDetails: {},
  appSettings: {},
  customAd: [],
  appPlan: [],
  planData: [],
  totalPlans: "",
  vipPlanData: [],
  totalVipPlan: "",
  appUser: [],
  appTotalUsers: "",
  appTotalUser: "",
  appNewUser: "",
  appDirectUser: "",
  appMarketThrough: "",
  isLoading: false,
  isSkeleton: false,
  total:null
};



export const getAllApp = createAsyncThunk(`/app/getApp`, async (payload) => {
  return apiInstanceFetch.get(
    `admin/app/get?start=${payload?.start}&limit=${payload.limit}`
  );
});



export const editAccountName = createAsyncThunk(
  "/app/editAccountName",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/app/editAccountName?appId=${payload?.appId}`,
      payload?.payloadData
    );
  }
);

export const editDomainName = createAsyncThunk(
  "/app/editdomainName",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/app/editdomainName?appId=${payload?.appId}`,
      payload?.payloadData
    );
  }
);


export const getDevloper = createAsyncThunk(
  `developer/developer/getAll`,
  async (payload) => {
    return apiInstanceFetch.get(`developer/developer/getAll`);
  }
);




export const addNewApp = createAsyncThunk(
  "admin/app/create",
  async (payload) => {
    return apiInstance.post("admin/app/create", payload);
  }
);


export const isPublishedSwitch = createAsyncThunk(
  "admin/app/isPublished",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/app/isPublished?appId=${payload?.appId}`
    );
  }
);

export const isAssignSwitch = createAsyncThunk(
  "admin/app/isAssign",
  async (payload) => {
    return apiInstanceFetch.patch(`admin/app/isAssign?appId=${payload?.id}`);
  }
);

export const isActiveSwitch = createAsyncThunk(
  "admin/plan/isActive?planId",
  async (payload) => {
    return apiInstanceFetch.patch(`admin/plan/isActive?planId=${payload?.id}`);
  }
);



export const deleteApp = createAsyncThunk(
  "admin/app/delete",
  async (payload) => {
    return apiInstanceFetch.delete(`admin/app/delete?appId=${payload}`);
  }
);

export const movedApp = createAsyncThunk(
  "admin/app/isMove",
  async (payload) => {
    return apiInstanceFetch.patch(`admin/app/isMove?appId=${payload}`);
  }
);



export const cloneApp = createAsyncThunk(
  "admin/app/appClone",
  async (payload) => {
    return apiInstanceFetch.post(`admin/app/appClone?originalAppId=${payload}`);
  }
);



export const moreAppSubmit = createAsyncThunk(
  "admin/app/createmoreApp",
  async (payload) => {
    return apiInstance.post(`admin/app/createmoreApp`, payload);
  }
);

export const updateApp = createAsyncThunk(
  "admin/app/updateAppInfo",
  async (payload) => {
    return apiInstance.patch(
      `admin/app/updateAppInfo?appId=${payload?.id}`,
      payload?.data
    );
  }
);

export const getMonetization = createAsyncThunk(
  `admin/appMonetization/getAppMonetizationOfApp`,
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/appMonetization/getAppMonetizationOfApp?appId=${payload}`
    );
  }
);



export const monetizationUpdate = createAsyncThunk(
  "admin/appMonetization/updateMonetization",
  async (payload) => {
    return apiInstance.patch(
      `admin/appMonetization/updateMonetization?appId=${payload?.appId}`,
      payload?.data
    );
  }
);

export const getCustomeAd = createAsyncThunk(
  `admin/customAd/getCustomAds`,
  async (payload) => {
    return apiInstanceFetch.get(`admin/customAd/getCustomAds`);
  }
);



export const customAdSubmit = createAsyncThunk(
  `admin/appMonetization/createCustomAd`,
  async (payload) => {
    return apiInstanceFetch.post(
      `admin/appMonetization/createCustomAd`,
      payload
    );
  }
);



export const appMonetizationSwitch = createAsyncThunk(
  `admin/appMonetization/isAppmonetizationSwitch`,
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/appMonetization/isAppmonetizationSwitch?appId=${payload.appId}&type=${payload.type}`
    );
  }
);



export const getAppDetails = createAsyncThunk(
  `admin/app/getAppdetails`,
  async (payload) => {
    return apiInstanceFetch.get(`admin/app/getAppdetails?appId=${payload}`);
  }
);


export const isHandleSwitchEdit = createAsyncThunk(
  `admin/app/isHandleSwitch`,
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/app/isHandleSwitch?appId=${payload.appId}&type=${payload.type}`
    );
  }
);



export const updateOtherSetting = createAsyncThunk(
  `admin/appMonetization/updateOtherSetting`,
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/appMonetization/updateOtherSetting?appId=${payload?.id}&type=${payload?.type}`,
      payload?.data
    );
  }
);



export const getSettingApp = createAsyncThunk(
  `admin/setting/getSetting`,
  async (payload) => {
    return apiInstanceFetch.get(`admin/setting/getSetting?appId=${payload}`);
  }
);



export const updateAppSetting = createAsyncThunk(
  `admin/setting/updateSetting`,
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/setting/updateSetting?appId=${payload?.appId}&settingId=${payload?.settingId}`,
      payload?.data
    );
  }
);


export const updateSettingInput = createAsyncThunk(
  "admin/setting/updatePSetting",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/setting/updatePSetting?appId=${payload?.appId}&settingId=${payload?.settingId}&type=${payload?.type}`,
      payload?.data
    );
  }
);

export const appSettingSwitch = createAsyncThunk(
  "admin/setting/p1TOp10",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/setting/p1TOp10?appId=${payload.appId}&settingId=${payload?.settingId}&type=${payload.type}`
    );
  }
);

export const getAppPlan = createAsyncThunk(
  "admin/plan/getPlanOfParticularApp",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/plan/getPlanOfParticularApp?appId=${payload}`
    );
  }
);

export const getPlan = createAsyncThunk(
  "admin/plan/getPlansOfParticularApp",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/plan/getPlansOfParticularApp?appId=${payload?.appId}&start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);

export const planUpdate = createAsyncThunk(
  "admin/plan/updatePlan",
  async (payload) => {
    return apiInstance.patch(
      `admin/plan/updatePlan?appId=${payload?.appId}&planId=${payload?.planId}`,
      payload?.data
    );
  }
);

export const paymentPlanSwitch = createAsyncThunk(
  "admin/plan/paymentGateway",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/plan/paymentGateway?appId=${payload?.appId}&planId=${payload?.planId}&type=${payload?.type}`,
      payload?.data
    );
  }
);

export const paymentSettingSwitch = createAsyncThunk(
  "admin/setting/paymentGateway",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/setting/paymentGateway?appId=${payload?.appId}&settingId=${payload?.settingId}&type=${payload?.type}`,
      payload?.data
    );
  }
);

export const deletePlan = createAsyncThunk(
  "admin/plan/destroy",
  async (payload) => {
    return apiInstanceFetch.delete(`admin/plan/destroy?planId=${payload}`);
  }
);

export const addPlan = createAsyncThunk(
  "admin/plan/createPlan",
  async (payload) => {
    return apiInstance.post(
      `admin/plan/createPlan?appId=${payload?.appId}`,
      payload?.data
    );
  }
);

export const appUserGet = createAsyncThunk(
  "admin/user/appWiseUsers",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/user/appWiseUsers?start=${payload?.start}&limit=${payload?.limit}&appId=${payload?.appId}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`
    );
  }
);

export const removeMoreApp = createAsyncThunk(
  "admin/app/removeMoreApp",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/app/removeMoreApp?appId=${payload.appId}&moreAppId=${payload?.moreAppId}`,
      payload?.data
    );
  }
);

export const removeCustomAd = createAsyncThunk(
  "admin/appMonetization/removeCustomAd",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/appMonetization/removeCustomAd?appId=${payload?.appId}&customAdId=${payload?.customAdId}`,
      payload?.data
    );
  }
);

export const getVipPlan = createAsyncThunk(
  "admin/vipPlan/getVipPlans",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/vipPlan/getVipPlans?appId=${payload?.appId}&start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);

export const vipPlanUpdate = createAsyncThunk(
  "admin/vipPlan/updateVipPlan",
  async (payload) => {
    return apiInstance.patch(
      `admin/vipPlan/updateVipPlan?appId=${payload?.appId}&planId=${payload?.planId}`,
      payload?.data
    );
  }
);

export const isActiveVipPlan = createAsyncThunk(
  "admin/vipPlan/isActive",
  async (payload) => {
    return apiInstanceFetch.patch(
      `admin/vipPlan/isActive?planId=${payload?.id}`,
      payload?.data
    );
  }
);

export const addVipPlan = createAsyncThunk(
  "admin/vipPlan/createVipPlan",
  async (payload) => {
    return apiInstance.post(
      `admin/vipPlan/createVipPlan?appId=${payload?.appId}`,
      payload?.data
    );
  }
);

export const deleteVipPlan = createAsyncThunk(
  "admin/vipPlan/destroy",
  async (payload) => {
    return apiInstanceFetch.delete(`admin/vipPlan/destroy?planId=${payload}`);
  }
);

export const getAllAppForDev = createAsyncThunk(`developer/developer/getAppsForDeveloper`, async (payload) => {
  
  return apiInstanceFetch.get(
    `developer/developer/getAppsForDeveloper?start=${payload?.start}&limit=${payload.limit}&developerId=${payload?.id}`
  );
});

const allAppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllAppForDev.pending, (state, action) => {
      state.isSkeleton = true;
      state.isLoading = false;

    });
    builder.addCase(getAllAppForDev.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.isLoading = false;

      if (action.payload.status === true) {
        state.allApp = action.payload.app;
        state.totalApp = action.payload.totalApp;
      }

    });
    builder.addCase(getAllAppForDev.rejected, (state, action) => {
      state.isSkeleton = false;
      state.isLoading = false;
    });

    builder.addCase(getAllApp.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getAllApp.fulfilled, (state, action) => {
      state.isSkeleton = false;
      if (action.payload.status === true) {
        state.allApp = action.payload.app;
        state.totalApp = action.payload.totalApp;
      }
    });
    builder.addCase(getAllApp.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(editAccountName.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(editAccountName.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allApp = state?.allApp?.map((app) => {
        if (app?._id === action.payload?.app._id) {
          return action.payload?.app;
        }
        return app;
      });
    });
    builder.addCase(editAccountName.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(editDomainName.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(editDomainName.fulfilled, (state, action) => { 
      
      state.isLoading = false;
      state.allApp = state.allApp?.map((app) => {
        if (app?._id === action.payload?.app?._id) {
          return action.payload?.app;
        }
        return app;
      });
    });
    builder.addCase(editDomainName.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getDevloper.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getDevloper.fulfilled, (state, action) => {
      state.isLoading = false;
      state.devloperAll = action.payload.developer;
    });
    builder.addCase(getDevloper.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(addNewApp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addNewApp.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.allApp.unshift(action.payload.app);
        setToast("success", "App New Create SuccessFull");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(addNewApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(isPublishedSwitch.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(isPublishedSwitch.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        if (action.meta.arg.apiType === "AllApp") {
          setToast(
            "success",
            "Status " +
              (action.meta.arg.value === true ? "Disabled" : "Enabled")
          );
          state.allApp = state.allApp = state.allApp?.map((app) => {
            if (app?._id === action.payload?.app?._id) {
              return action.payload?.app;
            }
            return app;
          });
        } else if (action.meta.arg.apiType === "AddDetail") {
          setToast(
            "success",
            "Status " +
              (action.meta.arg.value === true ? "Enabled" : "Disabled")
          );
          state.appDetails = action.payload.app;
        }
      }
    });
    builder.addCase(isPublishedSwitch.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(isAssignSwitch.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(isAssignSwitch.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.allApp = state.allApp = state.allApp?.map((app) => {
          if (app?._id === action.meta.arg?.id) {
            return action.payload?.app;
          }
          return app;
        });
        setToast(
          "success",
          `${
            action.meta.arg?.value === true
              ? "Not Assigned App"
              : "Assigned App"
          }`
        );
      }
    });
    builder.addCase(isAssignSwitch.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(isActiveSwitch.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(isActiveSwitch.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.planData = state.planData = state.planData?.map((app) => {
          if (app?._id === action.meta.arg?.id) {
            return action.payload?.plan;
          }
          return app;
        });
        setToast(
          "success",
          `${
            action.meta.arg?.value === true ? "In Active  Plan" : "Active Plan"
          }`
        );
      }
    });
    builder.addCase(isActiveSwitch.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteApp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteApp.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.allApp = state.allApp.filter((data) => {
          return data._id !== action.meta.arg;
        });
      }
      setToast("success", "App Delete SuccessFull");
    });
    builder.addCase(deleteApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(movedApp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(movedApp.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.allApp = state.allApp.filter((data) => {
          return data._id !== action.meta.arg;
        });
        setToast("success", "Moved App SuccessFull");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(movedApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(cloneApp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(cloneApp.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.allApp?.unshift(action?.payload?.clonedApp);
        setToast(
          "success",
          `${action?.payload?.clonedApp?.appName}` + " " + "Clone App Success"
        );
      }
    });
    builder.addCase(cloneApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateApp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateApp.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast(
          "success",
          `${
            action.payload?.app?.appName ? action.payload?.app?.appName : " "
          }` +
            " " +
            "App Update Successfully"
        );
        // state.allApp = state.allApp = state.allApp?.map((app) => {
        //   if (app?._id === action.meta.arg.id) {
        //     return action.payload?.app;
        //   }
        //   return app;
        // });
        state.appDetails = action.payload.app;
      }
    });
    builder.addCase(updateApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getMonetization.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getMonetization.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.monetizationData = action.payload.appMonetization;
      }
    });


    builder.addCase(getCustomeAd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomeAd.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.customAd = action.payload.customAd;
      }
    });
    builder.addCase(getCustomeAd.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(customAdSubmit.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(customAdSubmit.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast("success", "CustomeAds Added Successfully");
      }
    });
    builder.addCase(customAdSubmit.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(appMonetizationSwitch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(appMonetizationSwitch.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast(
          "success",
          action.meta.arg.type +
           " " + " updated successfully" 
           
        );
        state.monetizationData = action.payload.appMonetization;
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(appMonetizationSwitch.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(monetizationUpdate.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(monetizationUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast("success", "App Update Successfull");
        state.monetizationData = action.payload.appMonetization;
      }
    });
    builder.addCase(monetizationUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAppDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAppDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.appDetails = action.payload.app;
    });
    builder.addCase(getAppDetails.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(isHandleSwitchEdit.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(isHandleSwitchEdit.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast(
          "success",
          action.meta.arg.type +
            " " +
            (action.meta.arg.value === true ? "Disabled" : "Enabled")
        );
        state.appDetails = action.payload.app;
      } else {
        setToast("error", action.payload.message);
      }
    });

    builder.addCase(isHandleSwitchEdit.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateOtherSetting.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateOtherSetting.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast(
          "success",
          `${action.meta.arg?.showType}` + " " + " Update Successfull"
        );
        state.monetizationData = action.payload.otherSetting;
      }
    });
    builder.addCase(updateOtherSetting.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getSettingApp.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getSettingApp.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.appSettings = action.payload.setting;
      }
    });
    builder.addCase(getSettingApp.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateSettingInput.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateSettingInput.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast(
          "success",
          `${action.meta.arg?.showType}` + " " + " Update Successfull"
        );
        state.appSettings = action.payload.setting;
      }
    });
    builder.addCase(updateSettingInput.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(appSettingSwitch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(appSettingSwitch.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast(
          "success",
          action.meta.arg.type +
            " " +
            (action.meta.arg.value === true ? "Disabled" : "Enabled")
        );
        state.appSettings = action.payload.setting;
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(appSettingSwitch.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(paymentSettingSwitch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(paymentSettingSwitch.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast(
          "success",
          action.meta.arg.type +
            " " +
            (action.meta.arg.value === true ? "Disabled" : "Enabled")
        );
        state.appSettings = action.payload.setting;
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(paymentSettingSwitch.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(paymentPlanSwitch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(paymentPlanSwitch.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.planData = state.planData?.map((app) => {
          if (app?._id === action.meta.arg?.planId) {
            return action.payload?.plan;
          }
          return app;
        });
        setToast(
          "success",
          action.meta.arg.type +
            " " +
            (action.meta.arg.value === true ? "Disabled" : "Enabled")
        );
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(paymentPlanSwitch.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateAppSetting.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateAppSetting.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast("success", "App Settings Update SuccessFull");
        state.appSettings = action.payload.setting;
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(updateAppSetting.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getAppPlan.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAppPlan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.appPlan = action.payload.plan;
    });
    builder.addCase(getAppPlan.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getPlan.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getPlan.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.planData = action.payload.plan;
      state.totalPlans = action.payload.totalPlans;
    });
    builder.addCase(getPlan.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(deletePlan.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deletePlan.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.planData = state.planData.filter((data) => {
          return data?._id !== action.meta.arg;
        });
        setToast("success", "Plan Delete SuccessFull");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(deletePlan.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(moreAppSubmit.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(moreAppSubmit.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast("success", "MoreApp Added Successfully");
      }
      // const getId = action.payload?.app?.moreApp?.map((item) => item?._id);
      // const dataSet = (state.appDetails = {
      //   ...state.appDetails,
      //   moreApp: action.payload?.app?.moreApp,
      // });
      // state.appDetails = dataSet;
    });
    builder.addCase(moreAppSubmit.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(planUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(planUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.planData = state.planData?.map((app) => {
          if (app?._id === action.meta.arg?.planId) {
            return action.payload?.plan;
          }
          return app;
        });
        setToast(
          "success",
          `${
            action?.payload?.plan?.name ? action?.payload?.plan?.name : "App"
          }` +
            " " +
            "Plan Update SuccessFull"
        );
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(planUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getVipPlan.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getVipPlan.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.vipPlanData = action.payload.vipPlan;
      state.totalPlans = action.payload.totalVipPlans;
    });
    builder.addCase(getVipPlan.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(deleteVipPlan.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteVipPlan.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.vipPlanData = state.vipPlanData.filter((data) => {
          return data?._id !== action.meta.arg;
        });
        setToast("success", "VipPlan Delete SuccessFull");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(deleteVipPlan.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(isActiveVipPlan.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(isActiveVipPlan.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.vipPlanData = state.vipPlanData = state.vipPlanData?.map(
          (app) => {
            if (app?._id === action.meta.arg?.id) {
              return action.payload?.vipPlan;
            }
            return app;
          }
        );
        setToast(
          "success",
          `${
            action.meta.arg?.value === true
              ? "In Active VipPlan"
              : "Active VipPlan"
          }`
        );
      }
    });
    builder.addCase(isActiveVipPlan.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(vipPlanUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(vipPlanUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        state.vipPlanData = state.vipPlanData?.map((app) => {
          if (app?._id === action.meta.arg?.planId) {
            return action.payload?.vipPlan;
          }
          return app;
        });
        setToast(
          "success",
          `${
            action?.payload?.vipPlans?.name
              ? action?.payload?.vipPlans?.name
              : "App"
          }` +
            " " +
            "Vip Plan Update SuccessFull"
        );
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(vipPlanUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(addVipPlan.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addVipPlan.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.status === true) {
        state.vipPlanData.unshift(action.payload.vipPlan);
        setToast("success", "Vip Plan Created SuccessFull");
      } else {
        setToast("error", action.payload?.message);
      }
    });
    builder.addCase(addVipPlan.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(removeMoreApp.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(removeMoreApp.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast("success", "MoreApp Remove Successfully");
      }
    });
    builder.addCase(removeMoreApp.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(removeCustomAd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(removeCustomAd.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === true) {
        setToast("success", "CustomeAds Remove Successfully");
      }
    });
    builder.addCase(removeCustomAd.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(addPlan.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addPlan.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.status === true) {
        state.planData.unshift(action.payload.plan);
        setToast("success", "App Plan Created SuccessFull");
      } else {
        setToast("error", action.payload.message);
      }
    });
    builder.addCase(addPlan.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(appUserGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(appUserGet.fulfilled, (state, action) => {
      state.isSkeleton = false;
      state.appUser = action.payload.user;
      state.appMarketThrough = action.payload.marketThrough;
      state.appDirectUser = action.payload.directUser;
      state.appNewUser = action.payload.newUser;
      state.appTotalUser = action.payload.totalUser;
      state.appTotalUsers = action.payload.totalUsers;
    });
    builder.addCase(appUserGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});
export default allAppSlice.reducer;
