// export const baseURL = "http://192.168.29.136:6007/";
// export const key = "dbTmgroachhlJDGGzhRxgDYdKIvmraTs";

// export const baseURL = "http://192.168.29.183:5000";
// export const baseURL = "https://virtualdesk.codderlab.com";
// export const key = "dbTmgroachhlJDGGzhRxgDYdKIvmraTs";

// export const baseURL = "https://testvirtualdesk.codderlab.com";
export const baseURL = "https://testvirtualdesk.codderlab.com";
export const key = "vHlwi52J3p6Qq8gcxVHZJXEvMqrcaVwF";
    